<template>
    <section>
        <div class="row mx-0">
            <menu-categorias v-show="viewMenuCategorias" />
            <div class="col home-page pt-3 pl-4 overflow-auto scroll-none" style="height:calc(100vh - 98px);">
                <div class="row mx-0 mb-3">
                    <div v-for="(data, index) in pedidosModificados.pedidos" :key="index" class="col-xl-3 col-lg-4 col-md col-sm col-12">
                        <div class="card-modificado py-2 px-2">
                            <div class="row mx-0">
                                <div class="col-auto px-0 f-15 text-general">
                                    Pedido modificado
                                </div>
                                <div class="col-auto px-0 f-15 text-general f-600 ml-auto">
                                    {{ convertMoney(data.valor_final) }}
                                </div>
                            </div>
                            <button type="button" class="btn" @click="$router.push({name: 'pedidos.ver', params: { idPedido: data.id }})">
                                Revisar cambios <i class="icon-right-open f-14 ml-auto" />
                            </button>
                        </div>
                    </div>
                    <div v-if="pedidosModificados.cant_pendientes > 2" class="col-xl-3 col-lg-4 col-md col-sm col-12">
                        <div class="card-modificado py-2 px-2 btn" @click="$router.push({name: 'pedidos'})">
                            <div class="row mx-0 h-100 align-items-center">
                                <div class="col-auto px-0 text-general f-27 f-600">
                                    +{{ pedidosModificados.cant_pendientes -2 }}
                                </div>
                                <div class="col px-3 f-17 text-pedidos">
                                    Pedidos modificados por tu {{ $config.vendedor }}
                                </div>
                                <i class="icon-right-open f-14 ml-auto" />
                            </div>
                        </div>
                    </div>
                    <div class="col-12 my-2" />
                    <div v-if="pedidosModificados.cant_pedidos_act > 0" class="col-xl-3 col-lg-4 col-md col-sm col-12" @click="$router.push({name: 'pedidos'})">
                        <div class="btn-general position-relative" style="height:44px">
                            <div class="d-middle-center position-absolute f-600" style="width: 34px;height: 34px;border-radius: 8px;left: 8px;">
                                {{ pedidosModificados.cant_pedidos_act }}
                            </div>
                            Pedidos en proceso
                        </div>
                    </div>
                </div>
                <banners-promocionales />
                <slider-categorias />
                <section-footer class="mt-3 mt-lg-0" />
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import Modificados from '~/services/tienda/pedidos_modificados'
export default {
    components: {
        menuCategorias:()=> import('../categoria/components/menuCategorias.vue'),
        sliderCategorias:()=> import('../categoria/components/sliderCategorias.vue'),
        bannersPromocionales:()=> import('../promociones/components/bannersPromocionales.vue')
    },
    data(){
        return{
            idCategoria: null,
            pedidosModificados: []
        }
    },
    computed:{
        ...mapGetters({
            viewMenuCategorias:'home/viewMenuCategorias'
        })
    },
    mounted(){
        this.getPedidosModificados();
    },
    methods: {
        async getPedidosModificados(){
            try {
                const { data } = await Modificados.getInfoPedidosModificados()
                this.pedidosModificados = data.pendientes
            } catch (error){
                return this.errorCatch(error);
            }
        }
    }
}
</script>
<style lang="scss" scoped>

.panel-categories{
  height: calc(100vh - 80px);
  width: 245px;
  background-color: #FFFFFF;
  box-shadow: 0px 2px 4px #00000014;
  @media (min-width: 300px) and (max-width: 1024px) {
      height: calc(100vh - 96px);
  }
  @media (min-width: 300px) and (max-width: 1000px) {
      //display: none !important;
  }
}

.img-categories{
  box-shadow: 0px 3px 6px #00000029;
}

.tabs-mobile{
  display: none !important;
}

.banner-invitado{
    height: 113px;
    color: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0px 3px 6px #00000029;
    background-image: linear-gradient(to right, #612AD5,#9F4BFF, #F9C7FF,  #612AD5);
    .btn-registrarse{
        background-color: #FFFFFF;
        color: #612AD5;
        font-weight: 500;
        max-width: 182px;
        height: 44px;
        border-radius: 12px;
    }
}

.card-modificado{
    border: 1px solid #FF5806;
    background-color: #FFF3EA;
    //width: 312px;
    border-radius: 8px;
    height: 78px;
    .text-pedidos{
        height: 47px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: pre-line;
    }
    .btn{
        display: flex;
        align-items: center;
        color: #FFFFFF;
        height: 32px;
        border-radius: 12px;
        width: 100%;
        background-color: #FF5806;
    }
}

@media (min-width: 300px) and (max-width: 815px) {

  .title-category{
    display: none !important;
  }  

  .img-categories{
    width: 213px;
    height: 86px;
  }
  
  .tabs-mobile{
    display: block !important;
  }

  .titles{
    font-size: 17px !important;
    font-weight: 500 !important;
  }

  .spacer{
    display: none !important;
  }

  .text-arrow{
    display: none !important;
  }
  .like-bar{
    display: none !important;
  }

  
}


@media (min-width: 300px) and (max-width: 1024px) {
    .col.home-page{
          height: calc(100vh - 106px) !important;
      }
}

</style>
