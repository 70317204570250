import axios from 'axios';

const API = 'tienda';

const ENDPOINTS = {
    getInfoPedidosModificados(){
        return axios.get(`${API}/info/pedidos-modificados`)
    },
};

export default ENDPOINTS;
